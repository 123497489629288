<template>
  <v-app>
    <div class="vue green lighten-5">

      <v-app-bar app color="green" dark>

        <v-btn text fab  to="/"><v-icon>mdi-home</v-icon></v-btn>
        <h2 class="ml-4">Wagon Dashboard <span class="indice" style="color: #F8F8F8; text-decoration: none;">by <a href="https://www.linaware.eu" target="_blank" style="color: #F8F8F8;">Linaware</a></span></h2>
        <v-spacer></v-spacer>
        <div>{{$user.user.username}}</div>
        <v-btn text class="ml-4" @click="login" v-if="$user.id == ''">LOGIN</v-btn>
        <v-btn text class="ml-4" @click="logout" v-if="$user.id != ''">LOGOUT</v-btn>
      </v-app-bar>

      <v-main class="main">
        <router-view class="mx-1"></router-view>
      </v-main>

      <h-group class="piedpage py-1 px-2">
        <img :src="require('@/assets/logo_linaware.png')" class="mr-4" style="height: 50px; width: 50px" alt="Logo Linaware">

        <div>
          Wagon Dashboard — v1.56 —  &#169; <strong>Linaware</strong> 2020-{{ new Date().getFullYear() }} 
        </div>
        <div style="flex: 1;">
          <h-group style="justify-content: flex-end;">
            <div class="mr-4"><router-link to="/mentions">Mentions légages</router-link></div>
            <div><router-link to="/support">Support technique</router-link></div>
          </h-group>
        </div>
      </h-group>

    </div>
  </v-app>

</template>

<style lang="scss" scoped>
.indice {
  vertical-align : sub;
  font-size: 16px;
  font-style: italic;
  color: #F8F8F8;
  font-weight: normal;
}

.vue {
  /*width: 1910px;
  height: 1080px;*/
  /*background-color: green;*/
  padding: 5px;
  margin: 5px;
  /*border: 2px solid blue;*/
  overflow: hidden;
}

.main {
  /*height: 905px;*/
  text-align: center;
  height: calc(100vh - 64px);
  overflow: auto;
}

.piedpage {
  margin: 0 !important;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}


</style>

<script>

export default {
  components: {  },
  data() {
    return {

    }
  },

  watch: {
    $snackErreur: function() {
      console.log("SANCK !:!!!")
    }
  },

  methods: {
    logout() {
      this.$user.id = "";
      this.$user.ttl = 0;
      this.$user.created = "";
      this.$user.userId = -1;
      this.$user.roles = [];
      this.$user.user = {};
      localStorage.setItem('user', JSON.stringify(this.$user));
      if (this.$router.currentRoute.path != '/') {
        this.$router.push('/');
      }
      this.$forceUpdate();
    },

    login() {
      this.$router.push('/login')
    },

    /**
     * Redimensionnement automatique de l'application en fonction des dimensions du navigateur et du paramètre autoAdapt
     * Cette fonction est appelée sur l'évènement onResize du navigateur si autoAdapt != non
     */
     redimensionnerApplication() {
      //var b = document.getElementsByTagName("body")[0];
      //b.style.overflow = "auto"
      /*
      var w = window.innerWidth;
      var h = window.innerHeight;
      var scaleX = w / 1920;
      var scaleY = h / 980;
      this.autoAdapt = "xy"
      switch(this.autoAdapt)
      {
        case "xy":
          b.style.transform = "scale(" + scaleX.toFixed(3) + ", " + scaleY.toFixed(3) + ")";
          break;

        case "non":
          break;

        default:
          scaleX = Math.min(scaleX, scaleY)
          scaleY = Math.min(scaleX, scaleY)
          b.style.transform = "scale(" + scaleX.toFixed(3) + ", " + scaleY.toFixed(3) + ")";
          b.style.transformOrigin = "top";
          b.style.overflow = "hidden"
          break;
      }
      console.log("Window size: width=" + w + ", height=" + h + " " + scaleX + " " + scaleY);
      */
    },    
  },

  mounted() {
    setTimeout(function() {
        window.addEventListener("resize", this.redimensionnerApplication);
        this.redimensionnerApplication()
      }.bind(this), 100)
    
  },
}
</script>

import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import axios from 'axios'
import moment from 'moment'

import HGroup from '@/components/flex/HGroup.vue'
import VGroup from '@/components/flex/VGroup.vue'

Vue.prototype.moment = moment
Vue.prototype.$axios = axios;
Vue.component('HGroup', HGroup);
Vue.component('VGroup', VGroup);

// Droit utilisateur
Vue.prototype.$USER_ADMIN = false;
Vue.prototype.$USER_CONTAINERVIEW = false;
Vue.prototype.$USER_CONTAINERADMIN = false;
Vue.prototype.$USER_POVIEW = false;
Vue.prototype.$USER_SUIVITRAINMANAGER = false;
Vue.prototype.$USER_SUIVITRAIN = false;
Vue.prototype.$USER_KPI = false;


function updateRoles(roles) {
  Vue.prototype.$USER_ADMIN = false;
  Vue.prototype.$USER_CONTAINERVIEW = false;
  Vue.prototype.$USER_CONTAINERADMIN = false;
  Vue.prototype.$USER_POVIEW = false;
  Vue.prototype.$USER_SUIVITRAINMANAGER = false;
  Vue.prototype.$USER_SUIVITRAIN = false;
  Vue.prototype.$USER_POOLWAGONS = false;
  Vue.prototype.$USER_KPI = false;
  roles.forEach(role => {
    switch(role)
    {
      case 1: // Administration
        Vue.prototype.$USER_ADMIN = true;
        Vue.prototype.$USER_CONTAINERVIEW = true;
        Vue.prototype.$USER_CONTAINERADMIN = true;
        Vue.prototype.$USER_SUIVITRAINMANAGER = true;
        Vue.prototype.$USER_SUIVITRAIN = true;
        Vue.prototype.$USER_POOLWAGONS = true;
        break;
      case 2: // Visu des containers
        Vue.prototype.$USER_CONTAINERVIEW = true;
        break;
      case 3: // Administration des containers
        Vue.prototype.$USER_CONTAINERVIEW = true;
        Vue.prototype.$USER_CONTAINERADMIN = true;
        break
      case 4: // Visualisation des purchase orders
        Vue.prototype.$USER_POVIEW = true;
        break
      case 5: // Saisie des notes dans le suivi des trains
        Vue.prototype.$USER_SUIVITRAIN = true;
        Vue.prototype.$USER_SUIVITRAINMANAGER = true;
        break
      case 6: // Saisie des notes dans le suivi des trains
        Vue.prototype.$USER_SUIVITRAIN = true;
        break
        case 7: // Saisie des wagons pour le pool
        Vue.prototype.$USER_POOLWAGONS = true;
        break
      case 8: // Visualisation des KPI
      Vue.prototype.$USER_KPI = true;
        break
    }

  });
}
Vue.prototype.$updateRoles = updateRoles

// Constantes pour le statut des flux (feuilles de route)
Vue.prototype.$FLUX_STATUT_PREVISIONNEL = 0
Vue.prototype.$FLUX_STATUT_COUVERT = 1
Vue.prototype.$FLUX_STATUT_OUVERT = 2
Vue.prototype.$FLUX_STATUT_FERME = 3
Vue.prototype.$FLUX_STATUT_ANNULE = 4

Vue.prototype.$ROLE_ADMIN = 1
Vue.prototype.$ROLE_CONTAINERVIEW = 2
Vue.prototype.$ROLE_CONTAINERADMIN = 3
Vue.prototype.$ROLE_POVIEW = 4
Vue.prototype.$ROLE_SUIVITRAINMANAGER = 5
Vue.prototype.$ROLE_SUIVITRAIN = 6
Vue.prototype.$ROLE_POOLWAGONS = 7
Vue.prototype.$ROLE_KPI = 8


function json2Csv(json) {
  var fields = Object.keys(json[0])
  var replacer = function(key, value) { return value === null ? '' : value } 
  var csv = json.map(function(row){
    return fields.map(function(fieldName){
      return JSON.stringify(row[fieldName], replacer)
    }).join(';')
  })
  csv.unshift(fields.join(';')) // add header column
  csv = csv.join('\r\n');
  return csv;
}

function exporterCsv(tableau, nomFichier) {
  var csv = json2Csv(tableau);
  var downloadLink = document.createElement("a");
  var blob = new Blob(["\ufeff", csv]);
  var url = URL.createObjectURL(blob);
  downloadLink.href = url;
  downloadLink.download = nomFichier;

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}
Vue.prototype.$exporterCsv = exporterCsv


var defaultUser  = {
  "id": "",
  "ttl": 0,
  "created": "",
  "userId": -1,
  "email": "",
  "roles": [],
  "user": {}
}

const sUser = localStorage.getItem('user');
if (sUser != null) {
  const user = JSON.parse(sUser);
  const d = new Date(user.created);
  const dttl = new Date();
  dttl.setTime(d.getTime() + (user.ttl * 1000));
  if (dttl.getTime() > Date.now()) {
    defaultUser = user;
    updateRoles(defaultUser.roles);
  }
}

Vue.prototype.$user = defaultUser;

Vue.config.productionTip = false
const dayjs = require('dayjs')
require('dayjs/locale/fr')
dayjs.locale("fr")

Vue.prototype.$dayjs = dayjs

// Création de l'application Vue
new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
  

